import { postWithCancel } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api2.0";

export const getAudiencesGlobal = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.AUDIENCES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.AUDIENCES.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getAudiencesDaily = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.AUDIENCES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.AUDIENCES.DAILY
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getQuartilesGlobal = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.QUARTILES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.QUARTILES.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getPublishersHeatmap = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.PUBLISHERS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.PUBLISHERS.HEATMAP
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getProgramsGlobal = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.PROGRAMS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.PROGRAMS.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getDevicesGlobal = (axios, params = {}, data = {}, options = {}) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.DEVICES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.DEVICES.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getEpisodesGlobal = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.EPISODES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.EPISODES.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );
