<template>
  <div v-bind:class="cardSize" class="card-pi-podcast">
    <div class="card overflow-hidden border-0">
      <div class="card-body align-middle p-5">
        <h3
          style="max-width:262px; font-weight: 600; line-height: 29px"
          class="mb-4"
        >
          Boost your audience by launching a new campaign
        </h3>
        <router-link v-if="isAdmin()" :to="{ name: 'sponsoring_new' }">
          <span class="btn btn-info">Create campaign</span>
        </router-link>
        <UpgradePlan
          v-if="!isAdmin()"
          v-bind="{
            btnClass: 'btn btn-info',
            btnContent: 'Create campaign'
          }"
        ></UpgradePlan>
      </div>
      <inline-svg
        style="position:absolute; bottom: -50px; right:-150px; max-width: 100%;"
        src="assets/media/illustrations/space.svg"
      />
    </div>
  </div>
</template>

<script>
import UpgradePlan from "@/views/partials/widgets/UpgradePlan";
import { mapGetters } from "vuex";

export default {
  name: "CreateCampaignCards",
  props: {
    display: Boolean,
    sizes: {
      type: Object,
      required: true
    },
    image: String
  },
  components: {
    UpgradePlan
  },
  methods: {
    ...mapGetters(["isAdmin", "isPaidCustomer"])
  },
  computed: {
    cardSize() {
      let elementClass = "";
      Object.entries(this.sizes).forEach(([type, value]) => {
        if (type === "default") {
          elementClass += "col-" + value + " ";
        } else {
          elementClass += "col-" + type + "-" + value + " ";
        }
      });
      return elementClass;
    }
  }
};
</script>
