<template>
  <div :id="id"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      chart: {}
    };
  },

  mounted() {
    const chartDom = document.getElementById(this.id);
    this.chart = echarts.init(chartDom);

    this.options && this.chart.setOption(this.options);

    window.addEventListener("resize", () => this.chart.resize());
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.chart.resize(), false);
  }
};
</script>
