import { postWithCancel } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api2.0";

export const getFollowersGlobal = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.FOLLOWERS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.FOLLOWERS.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getFollowersDaily = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.FOLLOWERS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.FOLLOWERS.DAILY
        }
      ]
    },
    params,
    data,
    axios,
    options
  );
