<template>
  <AsynCardContainer
    :loading="loading"
    :sizes="{
      default: 12,
      sm: 12,
      lg: 6
    }"
    visibleCard
  >
    <div class="card-header-custom pt-4">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ title }}
      </h5>
    </div>

    <div class="w-100 d-flex flex-column align-items-center">
      <template v-if="!hasData">
        <span class="font-weight-bold">{{ $tc("NO_DATA_FOUND") }}</span>
      </template>

      <template v-if="hasData">
        <ApacheEchart
          :id="idGraph"
          :options="optionsGraph"
          class="w-100"
          :style="`min-height: 320px;`"
        />
      </template>
    </div>
  </AsynCardContainer>
</template>

<script>
import ApacheEchart from "@/components/charts/ApacheEchart";
import AsynCardContainer from "@/views/partials/content/cards/AsynCardContainer";

export default {
  components: {
    AsynCardContainer,
    ApacheEchart
  },

  props: {
    title: {
      type: String,
      required: false
    },
    idGraph: {
      type: String,
      required: true
    },
    optionsGraph: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    },
    dataLength: {
      type: Number,
      required: true
    }
  },

  computed: {
    hasData() {
      return this.dataLength > 0;
    }
  }
};
</script>
