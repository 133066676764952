<template>
  <AsynCardContainer
    :loading="loading"
    :sizes="{
      default: 12
    }"
    visibleCard
  >
    <div class="card-header-custom pt-4">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("AUDIENCE_PAGE.TITLE_RANKING_EPISODE") }}
      </h5>
    </div>

    <div class="pt-4">
      <b-table
        :items="episodes"
        :fields="headers"
        responsive
        striped
        hover
        bordered
        outlined
        show-empty
      >
        <template #empty>
          <div class="d-flex justify-content-center">
            <span class="font-weight-bold">{{
              $tc("DETAILS_V2.NO_STATS")
            }}</span>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle mr-1 spinner-voxeus-color"
            ></b-spinner>
            <strong>{{ $tc("DETAILS_V2.LOADING") }}</strong>
          </div>
        </template>

        <template #cell(top)="data">
          <div style="position: relative; text-align: center">
            <v-icon large color="yellow">mdi-star-outline</v-icon>
            <span
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 14px;
                font-weight: bold;
              "
              >{{ data.item.top }}</span
            >
          </div>
        </template>

        <template #cell(name)="data">
          <p v-b-tooltip.hover :title="data.item.name">
            {{ data.item.name | truncate(40, "...") }}
          </p>
        </template>

        <template #cell(play:total)="data">
          <p>{{ Number(data.item["play:total"]).toLocaleString() }}</p>
        </template>

        <template #cell(play:avg)="data">
          <p>{{ Number(data.item["play:avg"]).toLocaleString() }}</p>
        </template>

        <template #cell(ttimelistenings:total)="data">
          <p>{{ renderSeconds(data.item["ttimelistenings:total"]) }}</p>
        </template>

        <template #cell(ttimelistenings:avg)="data">
          <p>{{ renderSeconds(data.item["ttimelistenings:avg"]) }}</p>
        </template>
      </b-table>
    </div>
  </AsynCardContainer>
</template>

<style>
.spinner-voxeus-color {
  color: #ff961d !important;
}
</style>

<script>
import AsynCardContainer from "@/views/partials/content/cards/AsynCardContainer";
import { headers } from "@/common/config/dashboard/ranking";
import { renderSeconds } from "@/common/functions";

export default {
  data() {
    return {
      fields: headers,
      headers: headers
    };
  },

  components: {
    AsynCardContainer
  },

  props: {
    loading: {
      type: Boolean,
      required: false
    },
    episodes: {
      type: Array,
      required: false
    }
  },

  methods: {
    renderSeconds(time) {
      return renderSeconds(time);
    }
  },

  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  }
};
</script>
