<template>
  <div>
    <FiltersTemplate
      :inputFilters="inputFilters"
      :adminFilters="adminFilters"
      apiSlug="audience"
    />

    <AlertExpirePassword />

    <StatsPage
      v-bind:APIslug="'audience'"
      v-bind:tabsData="tabsData"
      v-bind:displayChart="true"
      v-bind:inputFilters="inputFilters"
      v-bind:adminFilters="adminFilters"
      v-bind:visibleCard="true"
      v-bind:displayCreate="true"
      v-bind:displayHeatmap="true"
      v-bind:displayDoughnutChart="true"
      v-bind:displayRankingEpisodes="true"
      class="kt-container mt-5"
    ></StatsPage>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import StatsPage from "@/components/layout/StatsPage";
import { mapGetters } from "vuex";
import { renderSeconds } from "@/common/functions";
import FiltersTemplate from "@/components/subheaders/filters/FiltersTemplate";
import {
  adminFilters,
  inputFilters
} from "@/common/config/filters/audiencesFollowers";
import AlertExpirePassword from "@/components/AlertExpirePassword";

const normalizeExportData = function(periodDatas) {
  delete periodDatas["total"];
  delete periodDatas["debugQuery"];
  let results = [];
  for (var key in periodDatas) {
    results.push({
      date: key,
      listens:
        new Intl.NumberFormat(navigator.language).format(
          periodDatas[key]["listens:total"]
        ) || 0,
      // page_views:
      //   new Intl.NumberFormat(navigator.language).format(
      //     periodDatas[key]["page:organic"] - periodDatas[key]["page:paid"]
      //   ) || 0,
      organic_listens:
        new Intl.NumberFormat(navigator.language).format(
          periodDatas[key]["listens:organic"]
        ) || 0,
      paid_listens:
        new Intl.NumberFormat(navigator.language).format(
          periodDatas[key]["listens:paid"]
        ) || 0,
      avg_listening_time: periodDatas[key]["atimelistenings:total"] || 0,
      total_listening_time: periodDatas[key]["ttimelistenings:total"] || 0
    });
  }
  return results;
};

export default {
  name: "audience",
  data() {
    return {
      timeout: 25000,
      tabsData: [
        {
          loading: true,
          title: "Listens",
          primaryStat: "NaN",
          previousCompare: "NaN",
          iconSVG: "assets/media/icons/svg/Media/Play3.svg",
          iconSVGColor: "primary",
          cardClass: "bg-pi",
          value: "listens:total",
          selected: true,
          renderFigure: function(x) {
            return new Intl.NumberFormat(navigator.language).format(x);
          },
          normalizeExportData: function(datas) {
            return normalizeExportData(datas);
          },
          chartOptions: {
            stacked: true
          },
          chartDataToDisplay: [
            {
              value: "listens:organic",
              color: "#FF961D",
              label: "Organic"
            },
            {
              value: "listens:paid",
              color: "#FF4867",
              label: "Paid"
            }
          ]
        },
        // {
        //   loading: true,
        //   title: "Sessions",
        //   primaryStat: "NaN",
        //   previousCompare: "NaN",
        //   iconSVG: "assets/media/icons/svg/Media/Playlist1.svg",
        //   iconSVGColor: "primary",
        //   cardClass: "bg-pi",
        //   value: "session:total",
        //   selected: false,
        //   renderFigure: function(x) {
        //     return new Intl.NumberFormat(navigator.language).format(x);
        //   },
        //   normalizeExportData: function(datas) {
        //     return normalizeExportData(datas);
        //   },
        //   chartOptions: {
        //     stacked: true
        //   },
        //   chartDataToDisplay: [
        //     {
        //       value: "session:organic",
        //       renderData: function(datas) {
        //         return datas["session:organic"];
        //       },
        //       color: "#FF961D",
        //       label: "Organic"
        //     }
        //   ]
        // },
        // {
        //   loading: true,
        //   title: "Session Conversion Rate",
        //   primaryStat: "NaN",
        //   previousCompare: "NaN",
        //   iconSVG: "assets/media/icons/svg/Shopping/Sale2.svg",
        //   iconSVGColor: "warning",
        //   cardClass: "bg-pi",
        //   value: "session:rate:total",
        //   selected: false,
        //   renderFigure: function(x) {
        //     return new Intl.NumberFormat(navigator.language).format(Math.round(x)) + ' %';
        //   },
        //   normalizeExportData: function(datas) {
        //     return normalizeExportData(datas);
        //   },
        //   chartOptions: {
        //     stacked: true
        //   },
        //   chartDataToDisplay: [
        //     {
        //       value: "session:rate:organic",
        //       renderData: function(datas) {
        //         return Math.round(datas["session:rate:organic"]);
        //       },
        //       color: "#FF961D",
        //       label: "Conversion Rate"
        //     }
        //   ]
        // },
        {
          loading: true,
          title: "Average listening time",
          primaryStat: "NaN",
          previousCompare: "NaN",
          iconSVG: "assets/media/icons/svg/Home/Timer.svg",
          iconSVGColor: "warning",
          theme: "light",
          value: "atimelistenings:total",
          selected: false,
          normalizeExportData: function(datas) {
            return normalizeExportData(datas);
          },
          chartOptions: {
            stacked: false
          },
          renderFigure: function(x) {
            return renderSeconds(x);
          },
          isTimeChart: true,
          chartDataToDisplay: [
            {
              type: "line",
              value: "atimelistenings:organic",
              color: "#FF961D",
              label: "Organic",
              borderColor: "#FF961D",
              renderData: function(datas) {
                return datas["atimelistenings:organic"];
              }
            },
            {
              type: "line",
              value: "atimelistenings:paid",
              color: "#FF4867",
              label: "Paid",
              borderColor: "#FF4867",
              renderData: function(datas) {
                return datas["atimelistenings:paid"];
              }
            }
          ],
          customOptions: {
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    maxRotation: 0,
                    autoSkipPadding: 100,
                    fontColor: "#B5B5C3"
                  },

                  stacked: false
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: false,
                    borderDash: [4, 8],
                    color: "#B5B5C3"
                  },
                  ticks: {
                    fontColor: "#B5B5C3",
                    maxTicksLimit: 10,
                    beginAtZero: true
                  },
                  stacked: false
                }
              ]
            }
          }
        },
        {
          loading: true,
          title: "Total listening time",
          primaryStat: "NaN",
          previousCompare: "NaN",
          iconSVG: "assets/media/icons/svg/Home/Timer2.svg",
          iconSVGColor: "success",
          theme: "light",
          value: "ttimelistenings:total",
          selected: false,
          normalizeExportData: function(datas) {
            return normalizeExportData(datas);
          },
          renderFigure: function(x) {
            return renderSeconds(x);
          },
          isTimeChart: true,
          chartDataToDisplay: [
            {
              value: "ttimelistenings:organic",
              color: "#FF961D",
              label: "Organic"
            },
            {
              value: "ttimelistenings:paid",
              color: "#FF4867",
              label: "Paid"
            }
          ]
        },
        {
          loading: true,
          title: "Completion data",
          primaryStat: "",
          previousCompare: "",
          iconSVG: "assets/media/icons/svg/Communication/Check.svg",
          iconSVGColor: "success",
          theme: "light",
          value: "cr",
          selected: false,
          dataSrc: `/v1/stats/dashboard/audience/daily`,
          isTimeChart: false,
          customOptions: {
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Completion data"
                  },
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    maxRotation: 0,
                    autoSkipPadding: 100,
                    fontColor: "#B5B5C3"
                  },

                  stacked: true
                }
              ],
              yAxes: [
                {
                  id: "cd",
                  scaleLabel: {
                    display: true,
                    labelString: "% of plays"
                  },
                  gridLines: {
                    display: true,
                    drawBorder: false,
                    borderDash: [4, 8],
                    color: "#B5B5C3"
                  },
                  ticks: {
                    fontColor: "#B5B5C3",
                    maxTicksLimit: 10,
                    beginAtZero: true,
                    precision: 0
                  },
                  stacked: false
                }
              ]
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  const datas = data.datasets[tooltipItem.datasetIndex].data;
                  const current = datas[tooltipItem.index];
                  const value = current.value;
                  const max = current.max;
                  const percent = Math.round((value / max) * 100);

                  const yLabel =
                    new Intl.NumberFormat(navigator.language).format(value) ||
                    0;

                  return `Plays : ${yLabel} (${percent} %)`;
                }
              }
            }
          },
          chartOptions: {
            chartType: "area"
          },
          chartDataToDisplay: [
            {
              value: "quartiles:organic",
              color: "rgba(255,150,29,0.3)",
              label: "Organic",
              borderColor: "#FF961D"
            },
            {
              value: "quartiles:paid",
              color: "rgba(255,72,103,0.2)",
              label: "Paid",
              borderColor: "#FF4867"
            }
          ]
        }
      ],
      adminFilters: adminFilters,
      inputFilters: inputFilters
    };
  },
  components: {
    StatsPage,
    FiltersTemplate,
    AlertExpirePassword
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Audience",
        pageCategory: "Analytics"
      }
    ]);
  },
  computed: {},
  watch: {},

  methods: {
    ...mapGetters(["isAdmin", "isPaidCustomer"])
  }
};
</script>
