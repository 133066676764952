var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TabsCards',{attrs:{"tabs":_vm.tabsData},on:{"change":_vm.selectTab}}),_c('v-row',{staticClass:"row-cards"},[_c('ChartCard',{attrs:{"sizes":{
        default: 12,
        sm: 12,
        lg: _vm.getLgColumns
      },"chart":_vm.chart,"customOptions":_vm.customOptions,"visibleCard":true}}),(_vm.isDisplayHeatmap)?_c('Heatmap',{attrs:{"loading":_vm.isLoadingHeatmap,"chartOptions":_vm.chartOptions,"series":_vm.series}}):_vm._e(),(_vm.isDisplayDoughnutChart)?_c('Chart',{key:"graph-program",attrs:{"id-graph":"graph-program","title":_vm.$tc('AUDIENCE_PAGE.TITLE_DOGHNUT_PROGRAMS'),"optionsGraph":_vm.doughnutChartPrograms,"loading":_vm.isLoadingDoughnutChart,"dataLength":_vm.doughnutChartPrograms.series[0].data.length}}):_vm._e(),(_vm.isDisplayDoughnutChart)?_c('Chart',{key:"graph-device",attrs:{"id-graph":"graph-device","title":_vm.$tc('AUDIENCE_PAGE.TITLE_CHART_DEVICE'),"optionsGraph":_vm.chartDevices,"loading":_vm.isLoadingDeviceChart,"dataLength":_vm.chartDevices.dataset.source.length}}):_vm._e(),(_vm.isDipayCreateCampaign)?_c('CreateCampaign',{staticStyle:{"min-height":"380px"},attrs:{"sizes":{
        default: 12,
        sm: 12,
        lg: 4
      }}}):_vm._e()],1),(_vm.isDisplayRankingEpisodes)?_c('v-row',{staticClass:"row-cards"},[_c('RankingEpisodes',{attrs:{"episodes":_vm.episodes,"loading":_vm.isLoadingEpisodes}})],1):_vm._e(),_vm._t("default",function(){return [(_vm.displayChart)?_c('v-row',{staticClass:"row-cards"},[_c('Table',{attrs:{"loading":_vm.chart.loading,"items":_vm.table.data,"visibleCard":true}})],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }