import i18n from "@/common/plugins/vue-i18n";

export const headers = [
  {
    label: i18n.t("RANKING_EPISODE.TOP"),
    key: "top",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.NAME"),
    key: "name",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.DOMAIN"),
    key: "domain",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.PROGRAM"),
    key: "program",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.LISTENING_TOTAL"),
    key: "play:total",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.LISTENING_AVG"),
    key: "play:avg",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.LISTENING_TIME_TOTAL"),
    key: "ttimelistenings:total",
    sortable: true
  },
  {
    label: i18n.t("RANKING_EPISODE.LISTENING_AVG_TIME"),
    key: "ttimelistenings:avg",
    sortable: true
  }
];
