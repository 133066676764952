<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <button v-on="on" :class="btnClass" v-html="btnContent"></button>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Contact us</span>
      </v-card-title>
      <v-card-text>
        Please contact us at <a :href="'mailto:' + email">{{ email }}</a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="dialog = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Config from "@/common/config";

export default {
  name: "upgrade-plan",
  data() {
    return {
      dialog: false,
      email: Config.get("contact_mail")
    };
  },
  components: {},
  props: {
    btnContent: String,
    btnClass: String
  },
  methods: {}
};
</script>
