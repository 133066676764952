let colorList = [
  "#FF961D",
  "#FFF01F",
  "#337DD6",
  "#21E5FF",
  "#8C1BF6",
  "#D445FF",
  "#CD9121",
  "#83C89B",
  "#017233",
  "#FF4867"
];

export const doughnutChartPrograms = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b}: {c} listens ({d}%)"
  },
  legend: {
    show: true,
    orient: "vertical",
    left: "left"
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: "10px"
  },
  series: [
    {
      name: "Total listens",
      type: "pie",
      radius: "90%",
      left: "250px",
      avoidLabelOverlap: false,
      color: colorList,
      label: {
        show: false,
        position: "center"
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)"
        }
      },
      labelLine: {
        show: false
      },
      data: []
    }
  ]
};

export const chartDevices = {
  dataset: {
    source: []
  },
  grid: { containLabel: true },
  xAxis: { type: "category" },
  yAxis: { name: "" },
  series: [
    {
      type: "bar",
      encode: {
        x: "device",
        y: "plays:total"
      },
      itemStyle: {
        color: function(param) {
          return colorList[param.dataIndex];
        }
      },
      label: {
        position: "outside",
        show: true,
        formatter: function(source) {
          return (
            source.data[2] + "  (" + `{bold|${source.data[0] + "%"}}` + ")"
          );
        },
        rich: {
          bold: {
            fontWeight: "bold"
          }
        }
      }
    }
  ]
};
