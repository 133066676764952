import { postWithCancel } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api2.0";

export const getRevenuesGlobal = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.REVENUES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.REVENUES.GLOBAL
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getRevenuesDaily = (axios, params = {}, data = {}, options = {}) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.REVENUES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.REVENUES.DAILY
        }
      ]
    },
    params,
    data,
    axios,
    options
  );

export const getRevenuesPrograms = (
  axios,
  params = {},
  data = {},
  options = {}
) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.DASHBOARD.REVENUES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DASHBOARD.REVENUES.PROGRAMS
        }
      ]
    },
    params,
    data,
    axios,
    options
  );
