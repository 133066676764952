<template>
  <AsynCardContainer
    :loading="loading"
    :sizes="{
      default: 12,
      sm: 12,
      lg: 6
    }"
    visibleCard
  >
    <div class="card-header-custom pt-4">
      <h5 class="card-title mb-0 font-weight-bold">
        {{ $tc("AUDIENCE_PAGE.TITLE_HEATMAP") }}
      </h5>
    </div>

    <div class="pt-4">
      <apexchart
        v-if="!loading"
        type="heatmap"
        width="100%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </AsynCardContainer>
</template>

<script>
import AsynCardContainer from "@/views/partials/content/cards/AsynCardContainer";

export default {
  props: {
    loading: {
      type: Boolean,
      required: false
    },
    chartOptions: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    }
  },

  components: {
    AsynCardContainer
  }
};
</script>
